const authenticate = {
    mounted() {
        if (!this.canApproveCurrentWorkflowRequest) {
            this.$router.push({
                name: "error",
                params: {
                    message: "UnAuthorized"
                },
            });
        } else {
            this.$store
                .dispatch("client/GetClientRequestWithTaskDetails", parseInt(this.id))
                .then((res) => {
                    this.ClientRequest = res.data;
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403) {
                        this.$router.push({
                            name: "error",
                            params: {
                                message: "UnAuthorized"
                            },
                        });
                    }
                    else{
                        this.Error = true;
                        alert(err);
                    }
                });
        }
    }
};
export default authenticate;