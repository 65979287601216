const ApproveLevel3 = "IC Approval", ApproveLevel1 = "Finance Approval", ApproveLevel2 = "AR Approval", OpenCodeInAxandDDs = "Open codes in AX and DDS", AxUpdateTheRequestWithSystemCode = "Update the request with System Code",Final = "Completed", Canceled = "Rejected";

const AllStates=[
    ApproveLevel1,
    ApproveLevel2,
    ApproveLevel3,
    AxUpdateTheRequestWithSystemCode,
    Final,
    Canceled,
]

const PendingRequestStates=[
    ApproveLevel1,
]
const PendingApprovalStates=[
    ApproveLevel2,
    ApproveLevel3,    
    OpenCodeInAxandDDs
]

const PendingCompletionStates=[
    AxUpdateTheRequestWithSystemCode,
]
export { Final,Canceled,ApproveLevel3,ApproveLevel1,ApproveLevel2,OpenCodeInAxandDDs,AxUpdateTheRequestWithSystemCode, AllStates,PendingApprovalStates,PendingCompletionStates,PendingRequestStates };