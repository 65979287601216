<template>
  <div>
    <div class="row page-title">
      <label>Request {{ ClientCode }}</label>
    </div>
    <div class="page-details row">
      <div class="text-center text-danger col-lg-12" v-if="IsDataLoaded&&!IsApproveOrRejectEnabled">The Request is already approved or rejected.</div>
      <client-overview   class="col-lg-9" :ClientRequest="ClientRequest"  :ID="id"></client-overview>
       <div class="col-lg-3 approval-side-section">
        <div class="row">
          <p class="approve-reject-label">Approve / Reject Client Request</p>
          <p class="approve-reject-content">
            Approve this request to send to the next approver.
          </p>
          <div class="text-right" style="width: 100%">
            <button
              v-if="IsApproveOrRejectEnabled"
              class="btn custom-button btn-primary"
              @click="Approve()"
            >
              Approve
            </button>
            <button
              v-else
              class="btn custom-button btn-primary"
              disabled="disabled"
              title="The request is already approved or rejected."
            >
              Approve
            </button>
          </div>
        </div>
        <div class="row reject-section">
          <label class="rejection-label">Rejection Comments</label>
          <b-textarea
            class="rejection-comments"
            placeholder="Enter Rejection Comments"
            v-model="Comments"
            v-bind:class="{
                  errorborder: !$v.Comments.required && $v.Comments.$dirty,
                  noterrorborder: $v.Comments.required,
                }"
            rows="5"
            @blur="SetDirty()"
            @focus="ResetDirty()"
          >
          </b-textarea>
          <div
            class="error-message-format required-field"
            v-if="!$v.Comments.required&& $v.Comments.$dirty"
          >
            Required field
          </div>
          <div class="text-right" style="width: 100%; padding-top: 10px">
            <button
              v-if="IsApproveOrRejectEnabled"
              class="btn custom-button btn-outline-primary"
              @click="Reject()"
            >
              Reject
            </button>
            <button
              v-else
              class="btn custom-button btn-outline-primary"
              disabled="disabled"
              title="The request is already approved or rejected."
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-form-group class="text-right footer-buttons" v-if="!Error&&!HideFooterButtons">
      <button class="btn custom-button btn-link" @click="Cancel()">
        Cancel
      </button>
      <button
        class="btn custom-button btn-outline-primary"
        @click="ShowReject()"
        v-if="IsApproveOrRejectEnabled"
      >
        Reject
      </button>
      <button
      v-else
        class="btn custom-button btn-outline-primary"
        title="The request is already approved or rejected."
        disabled="disabled"
      >
        Reject
      </button>

      <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-primary" @click="Approve()">
        Approve
      </button>
      <button v-else disabled="disabled" class="btn custom-button btn-primary" title="The request is already approved or rejected.">
        Approve
      </button>
    </b-form-group>
    <b-modal
      v-model="ShowRejectModal"
      :no-close-on-backdrop="true"
      scrollable
      title="Add Product"
    >
      <template v-slot:modal-header="{ close }">
        <h5 class="modal-title">{{ ModalTitle }}</h5>
        <button class="close" @click="CancelReject()"></button>
      </template>
      <div
        class="d-block row col-lg-6 col-md-8 offset-lg-2 offset-lg-3 primary-content"
      >
        <b-row>
          <b-col>
            <label>Rejection Comments</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-textarea v-model="Comments" rows="5"> </b-textarea>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="text-right">
          <button class="btn btn-link text-uppercase" @click="CancelAdd()">
            Cancel
          </button>
          <button
            :disabled="$v.$invalid"
            class="text-uppercase btn custom-button btn-primary"
            @click="Reject()"
          >
            Reject
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { requiredIf } from "vuelidate/lib/validators";
import store from "@/store";
import authenticate from "../../mixins/clientworkflowvauthorization";
import * as status from "../../utilities/ClientWorkflowStates";
export default {
  name: "ApproveLevel1",
  mixins: [authenticate],
  validations: {
    Comments: {
      required: requiredIf(function () {
        return this.RejectComments == true;
      }),
    },
  },
  data() {
    return {
      ClientRequest: {},
      Error: false,
      ShowRejectModal: false,
      Comments: "",
      HideFooterButtons:true,
      RejectComments:false
    };
  },
  props: ["id"],
  computed: {
    IsCommentsEntered() {
      return this.Comments != null && this.Comments.length > 0 && this.Comments.toString().trim().length>0;
    },
    ClientCode() {
      return this.ClientRequest ? this.ClientRequest.RequestedClientCode : "";
    },
    ModalTitle() {
      return `Reject Request ${this.ClientName}`;
    },
    canApproveCurrentWorkflowRequest() {
      return this.$store.getters.canApproveClientRequestLevel1;
    },
    IsApproveOrRejectEnabled(){
      return this.ClientRequest&&this.ClientRequest.TaskStatus==status.ApproveLevel1;
    },
    IsDataLoaded() {
      return this.ClientRequest &&this.ClientRequest.RequestedClientCode ? true : false;
    },
  },
  methods: {
    SetDirty(){
  this.$v.Comments.$touch();
    },
    ResetDirty(){
  this.$v.Comments.$reset();
    },
    GetData() {
      return {
        ID: parseInt(this.id),
        Comments: this.Comments,
        ActiveTaskID: this.ClientRequest.ActiveTaskID,
        ProjectID: this.ClientRequest.OmniflowProjectID,
        ProcessID: this.ClientRequest.OmniflowProcessID,
      };
    },
    ShowReject() {
      this.ShowRejectModal = true;
    },
    CancelReject() {
      this.ShowRejectModal = false;
    },
    Reject() {
      this.ShowRejectModal = false;
      this.RejectComments=true;
       this.$v.Comments.$touch();
      if(!this.$v.$invalid){
      this.$store
        .dispatch("client/RejectRequestLevel1", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `Request ${this.ClientCode} has been rejected`
          );
          this.$router.push("/ClientProduct");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while rejecting request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while rejecting request", ex);
          }
        });
      }
    },
    Approve() {
      this.ShowRejectModal = false;
      this.RejectComments=false;

      this.$store
        .dispatch("client/ApproveRequestLevel1", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The request ${this.ClientCode} has been approved`
          );
          this.$router.push("/ClientProduct");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while approving request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while approving request", ex);
          }
        });
    },
    SetErrorMessage(message, ex) {
      store.dispatch(
        "progress/setErrorMessage",
        `${message} ${this.ClientCode}. The error is ${ex}`
      );
    },
    Cancel() {
      this.$router.push("/ClientProduct");
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 1200px) {
  .page-details {
    padding: 1rem 3rem 0px !important;
  }
}

.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}
.review-component>div:first-child {
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%) !important;
    border: solid 1px rgba(239, 242, 244, 0.2) !important;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
     background-color: #ffffff;
}
</style>